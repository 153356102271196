<template>
   <div>
      <div v-if="activity.step <= 0" class="d-flex align-center flex-column grey--text text-center" style="height: 100vh; position: relative;">
         <div style="z-index: 1;">
            <v-img v-if="$store.state.setting.global.Logo" :src="setUrlFromImage($store.state.setting.global.Logo)" contain width="96" class="mx-auto" style="margin-top: 60px;" />
            <v-img v-else :src="require('@/assets/logo.svg')" contain width="84" class="mx-auto" style="margin-top: 60px; filter: invert(22%) sepia(43%) saturate(3402%) hue-rotate(189deg) brightness(65%) contrast(99%);" />
            <p class="text-uppercase text-h6 primary--text mt-3">
               Registration
            </p>
         </div>
         <div v-if="activity.step === 0" class="my-auto px-10 text-center" style="z-index: 1;">
            <p>
               Verifying telegram ID
            </p>
            <v-progress-linear height="16" striped indeterminate rounded />
         </div>
         <div v-if="activity.step < 0" class="my-auto px-10 text-center" style="z-index: 1;">
            <p class="text-uppercase text-h5">
               <span class="red--text text--darken-2">{{ error }}</span><br>Telegram ID
            </p>
            <v-icon class="mb-3" style="font-size: 180px;">
               mdi-telegram
            </v-icon>
            <p class="grey--text">
               <!-- Mohon maaf, tenant kami sedang tidak menerima pengunjung, silakan hubungi kembali -->
               Silakan melakukan subscribe melalui aplikasi telegram ke {{ isOffice8 ? '@office8bot' : '@nvm50bot' }} sebelum melengkapi data organisasi anda
            </p>
         </div>
         <p class="mt-auto" style="z-index: 1;">
            {{ `${$store.state.setting.global.title} v${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}` }}
         </p>
         <div style="position: absolute; bottom: 0; width: 100%; height: 100%; clip-path: circle(50% at 50% 100%); background-color: #e3f2fd80;" />
      </div>
      <div v-if="activity.step === 1" class="mt-3 mx-2">
         <v-card-text class="pb-5">
            <v-form ref="form">
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.name" label="Your Name" placeholder="Nama Operator" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-autocomplete :items="filteredOrganizations" label="Organization" placeholder="Ketik lalu PILIH" outlined hide-details required :rules="[v => !!v]"
                        item-text="name" item-value="identity" no-data-text="Typeahead and choose one"
                        prepend-inner-icon="mdi-database-search"
                        :return-object="true"
                        :menu-props="{ offsetY: true, bottom: true }"
                        :search-input.sync="search.input"
                        @change="changeOrganization"
                        />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-select v-model="select.floor" :items="select.floors" label="Floors" placeholder="Pilih Lantai" outlined hide-details multiple
                        item-text="name" item-value="identity"
                        :return-object="false" :menu-props="{ offsetY: true, bottom: true }"
                        :disabled="!(search.organization && search.organization.identity)"
                        >
                        <template v-slot:selection="{ item, index }">
                           <span v-if="index < 1">{{ index === 0 ? item.name : `, ${item.name}` }}</span>
                           <span v-if="index === 1" class="grey--text ml-2">
                              ( +{{ select.floor.length - 1 }} more )
                           </span>
                        </template>
                     </v-select>
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="4">
                     <v-text-field :value="`+${form.phone}`" label="Phone" placeholder="Phone" outlined hide-details readonly />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.email" label="Email" placeholder="Alamat Surel" outlined hide-details required :rules="[v => /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.password" label="Password" placeholder="Kata Rahasia" outlined hide-details required :rules="[v => !!v]"
                        :type="(form.id===0&&seen)?'text':'password'" :append-icon="form.id===0?seen?'mdi-eye':'mdi-eye-off':''"
                        @click:append="seen=!seen"
                        />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" class="d-flex justify-end" :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }">
                     <v-btn large dark color="teal darken-2" depressed rounded class="mt-0" @click="updateRecord">
                        <!-- @click="activity.step = 2" -->
                        Submit
                     </v-btn>
                  </v-col>
               </v-row>
            </v-form>
         </v-card-text>
      </div>
      <div v-if="activity.step === 2" style="position: relative;">
         <div class="d-flex align-center flex-column primary--text text-center" style="height: 100vh;">
            <p class="mt-9 mb-3 grey--text" style="z-index: 1;">
               Your Tenant ID
            </p>
            <p class="text-uppercase text-h3" style="z-index: 1; font-size: 2.5rem !important;">
               <!-- 427518496 -->
               {{ form.telegram }}
            </p>
            <div class="my-auto" style="z-index: 1;">
               <p class="text-uppercase text-h5">
                  Success
               </p>
               <v-icon large style="font-size: 120px;">
                  mdi-checkbox-marked-circle-outline
               </v-icon>
               <p class="mt-2 grey--text">
                  Please follow next instructions<br>to give your visitor access to entry
               </p>
            </div>
            <p class="mt-auto grey--text" style="z-index: 1;">
               {{ `${$store.state.setting.global.title} v${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}` }}
            </p>
         </div>
         <div style="position: absolute; top: 0; width: 100%; height: 75%; clip-path: circle(50% at 50% -5%); background-color: #e3f2fd80;" />
         <div style="position: absolute; bottom: 0; width: 100%; height: 75%; clip-path: circle(50% at 50% 115%); background-color: #e3f2fd80;" />
      </div>
   </div>
</template>

<script>
import { version } from '../../../package'
import { imageFromUrl } from '@/utils/nirtara'
import { receptionist } from '@/utils/fields.js'
export default {
   name: 'NRegistrationForm',

   props: {
      activity: { type: Object },
      organizations: { type: Array },
      floors: { type: Array },
   },
   data: () => ({
      isUsed: false,
      isOffice8: false,
      version: version.replace(/\D/g, '').slice(-5),
      form: Object.assign({}, receptionist),
      seen: false,
      search: { input: null, organization: {}, organizations: [] },
      select: { floor: [], floors: [] },
      error: 'Invalid',
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredOrganizations () {
         return (this.search.input && this.search.input.length > 0)
            ? this.organizations
            : []
      },
   },
   watch: {
      'activity.id' (id) {
         if (typeof id === 'string') {
            const { form, activity } = this
            activity.step = activity.status === 'Disabled' ? -1 : 1
            activity.status === 'Disabled' && (this.error = 'Disabled')
            // console.log('watch (activity)', JSON.parse(JSON.stringify(activity)))
            Object.assign(form, receptionist, activity)
         }
      },
   },
   created () {
   },
   mounted () {
      this.$emit('select')
      // console.log(`mounted ( hostname = ${location.hostname} )`)
      this.isOffice8 = location.hostname.indexOf('office8.co.id') !== -1
   },
   methods: {
      changeOrganization (item) {
         // console.log('changeOrganization (item)', item && JSON.parse(JSON.stringify(item)))
         this.search.organization = item
         this.select.floor = []
         item && (this.select.floors = item.Floors.map(j => (this.floors.find(f => f.identity === j.identity) || { name: j.identity, identity: j.identity })))
      },
      updateRecord () {
         // this.activity.step = 2
         if (!this.$refs.form.validate()) return
         // console.log('updateRecord (this.select.floor)', this.select.floor)
         this.$emit('update', Object.assign({}, this.form, {
            organization: this.search.organization.identity,
            Floors: this.select.floor.map(s => { return { Floor: [], identity: s } }),
         }))
      },
   },
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--large {
   height: 44px;
   min-width: 150px;
}
.mdi-checkbox-marked-circle-outline {
  background: radial-gradient(
    circle at 30% 107%,
    #00695c 0%,
    #009688 5%,
    #2196f3 60%,
    #0d47a1  90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.v-progress-linear--rounded {
   border-radius: 8px;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px !important;
}
</style>
